import DTOEmpleado from '../../Componest/Catalogos/CatEmpleados/DTO/DTOEmpleado'
import http from '../common/http-common'


class CatEmpleados {

 GetAllEmpleado() 
 {
    return http.get<DTOEmpleado[]>('/Catalogos/CatalogoEmpleado/GetAllEmpleado')
 }

//  PostInsertUpdate(data: DTOEmpleado) {
//     return http.post<DTOEmpleado[]>('/Catalogos/CatalogoEmpleado/InsertUpdate', data, {
//       headers: {
//         'Content-Type': 'application/json'  // Asegurarse de enviar el contenido como JSON
//       }
//     });
//   }
  



PostInsertUpdate(data: any) {
  return http.post<DTOEmpleado[]>('/Catalogos/CatalogoEmpleado/InsertUpdate', data);
}




DeleteEmpleado(IdEmpNo: number) {
    return http.delete<DTOEmpleado[]>(`/Catalogos/CatalogoEmpleado/Delete`, {
      params: {
        IdEmpNo: IdEmpNo  // Pasamos el parámetro como parte de la consulta
      }
    });
  }
  




}


export default new CatEmpleados()