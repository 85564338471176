import * as react from 'react'
import { useState } from 'react'
import reportesServices from '../../../Services/Reportes/reportes.services'
import DataTable from 'react-data-table-component'
import {DTOTotalHours} from '../../../DTO/Reportes/DTOTotalHours'
import *  as XLSX from 'xlsx'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import { Button, Card, Form,  Col, Row, ToastHeader, CardBody,  } from 'react-bootstrap';
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
import { DTOWorkingHours } from '../../../DTO/DTOWorkingHours'



export default function RptTotalHours ()
{
    const [Inicio, setInicio] = useState(currentDate()) 
    const [Fin , setFin] = useState(currentDate)
    const [filtro, setFiltro] = useState('')
    const [filteredData, setFilteredData] = useState<Array<DTOTotalHours>> (
        []
    )
    const [filteredDataDet, setFilteredDataDet] = useState <Array<DTOWorkingHours>>(
      []
    )

    const [Data, setData] = useState<Array<DTOTotalHours>> ([])
    const [DataDet , setDataDet ] = useState <Array <DTOWorkingHours>> ([])
    const [showToast, setShowToast] = useState<boolean>(false);
    const [toastMsg, setToastMsg] = useState<string>('');
    const [toastHeader, setToastHeader] = useState<string>('');
    const [toastColor, setToastColor] = useState<string>('');


  function currentDate ():  string {
    var today = new Date()
    var dd = String(today.getDate()).padEnd(2,'0')
    var mm = String(today.getMonth() + 1  ).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy+'-' + mm + '-'  + dd
  }

  const generarRptRes = ()  => {
    reportesServices.getRptTotalHours(Inicio, Fin)
    .then((response) => {
      setData(response.data)
      setFilteredData(response.data)
      setToastMsg('Se econtro la siguiente informacion');
      setToastHeader('Informativo');
      setToastColor('success');
      setShowToast(true);

    }) 
    .catch((e:Error)   =>  {
      // alert('Ocurrio un Error'+e.message.toString())
      setToastMsg('No se ha introducido fechas validas')
      setToastHeader('Error')
      setToastColor('danger')
      setShowToast(true)
      return; 
    }  )
  }

  const generarRptDet = () => {
    reportesServices.getRptWorkingHours(Inicio, Fin)
      .then((response) => {
        setDataDet(response.data);
      })
      .catch((e: Error) => {
        setToastMsg('Fechas No Validas');
        setToastHeader('Error');
        setToastColor('danger');
        setShowToast(true);
      });
  };

 




  const columnsConcepts = [
    {
        name: 'EmpNo', 
        width : '100px',
        selector : (row : DTOTotalHours ) => row.empNo, 
        sortable: true, 
        filter : true
    }, 


    
    {
        name: 'AcNo', 
        width : '100px', 
        selector : (row : DTOTotalHours) => row.acNo, 
        sortable : true, 
        filter : true 
    }, 
    {
        name: 'Name', 
        width : '200px', 
        selector: (row: DTOTotalHours)  => row.name, 
        sortable: true, 
        filter: true 

    }, 
    {
        name: 'Payroll', 
        width : '100px', 
        selector : (row : DTOTotalHours) => row.payroll, 
        sortable : true, 
        filter : true 
    },
    {
      name: 'Oficina', 
      width: '150px', 
      selector: (row: DTOTotalHours) => row.oficina, 
      sortable : true, 
      filter:  true
    },
    {
      name: 'HoraEntrada', 
      width: '150px', 
      selector : (row: DTOTotalHours) => row.horaEntrada, 
      sortable : true, 
      filter : true 

    }, 
    {
      name: 'HoraSalida', 
      width : '150px', 
      selector: (row: DTOTotalHours) => row.horaSalida, 
      sortable : true, 
      filter: true
    }, 
    {
      name : 'HoraSalidaComer', 
      width : '150px', 
      selector : (row: DTOTotalHours) =>row.horaSalidaComer, 
      sortable : true, 
      filter: true
    }, 
    {
      name : 'HoraEntradaComer', 
      width : '160px', 
      selector : (row: DTOTotalHours) => row.horaEntradaComer, 
      sortable : true, 
      filter: true
    },
    
    {
        name: 'TotalHoursAccumulated', 
        width: '200px', 
        selector : (row: DTOTotalHours ) => row.totalHoursAccumulated, 
        sortable : true, 
        filter : true
    }, 
    {
      name: 'TotalHoursWithMilitaryMinutes', 
      with: '50px', 
      selector : (row: DTOTotalHours) => row.totalHoursWithMilitaryMinutes, 
      sortable: true, 
      filter : true
    },
    
    {
      name : 'Faltas', 
      width : '100px', 
      selector : (row: DTOTotalHours) => row.faltas, 
      sortable : true, 
      filter : true 
    }, 
    {
      name: 'Retardos', 
      width : '100px', 
      selector : (row: DTOTotalHours) => row. retardos, 
      sortable: true, 
      filter : true, 
    }, 
    {
      name: 'HorasExtras', 
      width : '150px', 
      selector : (row: DTOTotalHours) => row.horasExtras, 
      sortable : true, 
      filter: true
    }

     
    

  ]

  const columnsConceptsDet =  [
    [
      {
        name: 'Name' , 
        selector : (row: DTOWorkingHours ) => row.name, 
          sortable : true, 
          filter : true
      }, 
      {
        name: 'EmpNo', 
        
        selector : (row : DTOWorkingHours ) => row.empNo, 
        sortable: true, 
        filter : true
    }
    ]
  ]
    



  // const donwloadExel = () =>  {
  //   exportExcel(filteredData, 'ReporteTotalHoras' ) 
  // }


  // const exportExcel = (jsonData: any[], fileName: string) => {
  //   const Heading = [
  //     [
  //       'EmpNo', 
  //       'AcNo', 
  //       'No',
  //       'TotalHoursAccumuled',
  //       'TotalHoursWithMilitaryMinutes'
        
  //     ]


  //   ]



  //   const Heading1 = [[
  //     'EmpNo', 'AcNo', 'Name', 'Date', 'ClockIn1', 'ClockOut1   ' ,'ClockIn1', 'ClockOut1   ' , 
  //     'ClockIn2', 'ClockOut2 ',  'ClockIn3', 'ClockOut3   ',  'ClockIn4', 'ClockOut4   ',
  //     'ClockIn5', 'ClockOut5  '


  //   ]]


  //   const wb = XLSX.utils.book_new()
  //   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
  //   XLSX.utils.sheet_add_aoa(ws, Heading)
  //   XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
  //   XLSX.writeFile(wb, `${fileName}.xlsx`)
  //   const range = XLSX.utils.decode_range(ws['!ref']!)
  //   for (let C = range.s.c; C <= range.e.c; ++C) {
  //     const address = XLSX.utils.encode_col(C) + '1'
  //     if (!ws[address]) continue
  //     ws[address].v = ws[address].v.toUpperCase()
  //   }


  //  }






  const donwloadExel = () => {
    exportExcel(filteredData, DataDet, 'ReporteTotal'); 
    
    
  };
  


  
  


  
  const exportExcel = (jsonData1: any[], jsonData2: any[], fileName: string) => {
    const Heading1 = [
      [
        'EmpNo', 
        'AcNo', 
        
        'Name', 
        'Payroll', 
        'Oficina', 
        'HoraEntrada', 
        'HoraSalida', 
        'HoraSalidaComer', 
        'HoraEntradaComer', 
        'TotalHoursAccumuled', 
        'TotalHoursWithMilitaryMinutes', 
        'Faltas', 
        'Retardos', 
        'HorasExtras'
      ]
    ];
  
    const Heading2 = [
      [
        'EmpNo', 
        'AcNo', 
        'No',
        'Name',
        'Date', 
        'ClockIn1', 
        'ClockOut1', 
        
        'ClockIn2', 
        'ClockOut2', 
        'ClockIn3', 
        'ClockOut3', 
        'ClockIn4', 
        'ClockOut4', 
        'ClockIn5', 
        'ClockOut5', 
        'TotalInTime', 
        'esFestivo', 
        'TotalHoras'
      ]
    ];
  
    // Crear libro de trabajo
    const wb = XLSX.utils.book_new();
  
    // Hoja 1
    const ws1: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws1, Heading1);
    XLSX.utils.sheet_add_json(ws1, jsonData1, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws1, 'Resumen');
  
    // Hoja 2
    const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws2, Heading2);
    XLSX.utils.sheet_add_json(ws2, jsonData2, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws2, 'Detalle');
  
    // Convertir títulos a mayúsculas en ambas hojas
    const capitalizeHeaders = (ws: XLSX.WorkSheet) => {
      const range = XLSX.utils.decode_range(ws['!ref']!);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + '1';
        if (!ws[address]) continue;
        ws[address].v = ws[address].v.toUpperCase();
      }
    };
  
    capitalizeHeaders(ws1);
    capitalizeHeaders(ws2);
  
    // Escribir y descargar el archivo Excel
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  






    const filtraReporte1 = (e: any)  =>  {
        const searchText = e.target.value.toLowerCase(); 
        setFiltro(searchText)

        const filtered = DataDet.filter((iteam) =>  {
            return (
                (iteam.empNo && iteam.empNo.toString().toLocaleUpperCase().includes(searchText))  || 
                (iteam.name  && iteam.name.toString().toLocaleLowerCase().includes(searchText)   ) 
                

            )
        } )

        setFilteredDataDet(filtered)
       
    } 





    const filtraReporte = (e: any)  =>  {
      const searchText = e.target.value.toLowerCase(); 
      setFiltro(searchText)

      const filtered = Data.filter((iteam) =>  {
          return (
              (iteam.empNo && iteam.empNo.toString().toLocaleUpperCase().includes(searchText))  || 
              (iteam.name  && iteam.name.toString().toLocaleLowerCase().includes(searchText)   ) 
              

          )
      } )

      setFilteredData(filtered)
     
  } 
    
    

    const  generReportes = () => {
      generarRptRes()
      generarRptDet()
    }  

    

    // const filtros = (e: any) => {
    //   filtraReporte(e);
    //   filtraReporte1(e);
    // }

    



    return(
        <div className='mt-2'> 
         {/* <Card className="mt-3"> */}
      <Card.Body>
        <Form>
          <Row className="align-items-center">
            <Col xs={1}>
              {/* <Form.Label>Desde</Form.Label> */}

              <Col style={{textAlign: 'center'}} >Desde</Col>
              <Form.Control
                defaultValue={Inicio}
                type="date"
                name="Inicio"
                placeholder="Inicio"
                title="Inicio"
                alt="Inicio"
                data-date-format="YYYY-mm-dd"
                onChange={(e) => setInicio(e.target.value)}
                size="sm"
              />
            </Col>

            <Col xs={1}>
              {/* <Form.Label>Hasta</Form.Label> */}
              <Col style={{textAlign: 'center'}} >Hasta</Col>
              <Form.Control
                defaultValue={Fin}
                type="date"
                name="Fin"
                placeholder="Fin"
                title="Fin"
                alt="Fin"
                onChange={(e) => setFin(e.target.value)}
                size="sm"
              />
            </Col>

            <Col xs={2}>
              <Form.Control
                type="text"
                size="sm"
                placeholder="Search..."
                onChange={ (e) =>  {
                  filtraReporte(e)
                }  }
              //  onChange={filtros}
                style={{ height: '10px', padding: '5px' }}
              />
            </Col>

            <Col xs={2}>
              <Button
                // size="sm"
                variant="success"
                onClick={donwloadExel}
                style={{ width: '35%' }}
              >
                <BsFileEarmarkExcel />
                &nbsp;Excel
              </Button>
            </Col>

            <Col xs={2}>
              <Button
                variant="primary"
                onClick={generReportes} 
                style={{ width: '40%' }}
              >
                <BsSearch />
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    {/* </Card> */}

    <MsgInformativo
      show={showToast}
      msg={toastMsg}
      header={toastHeader}
      msgColor={toastColor}
      closeToast={setShowToast}
    />
        

        <Card className='mt-3'> 

            <Card.Body> 
                <div className='ag-theme-alpine' style={{ height: 500, width: '100%'}}>

                    <DataTable 
                     noHeader 
                     defaultSortFieldId={''}
                     defaultSortAsc = {true}
                     striped= {true}
                     dense = {true}
                     paginationPerPage={10}
                     pagination 
                     highlightOnHover 
                     columns={columnsConcepts}
                     data={filteredData}
                     pointerOnHover



                    />
                </div>
            </Card.Body>
        </Card>
                


        </div>  

    )



}